.addBtn {
    position: absolute !important;
    /* top: 0% !important; */
    left: 88% !important;
    padding: 0px;
    
    width: 10%;
    height: 35px;

}

.toast-container {
    position: fixed !important;
    top: 20px !important;
    right: 20px !important;
    z-index: 9999 !important;
}


.swiperImage{
    height: 300px;
    width: 100%;
}

.table > thead {
    font-size: 14px !important;
}

.table > tbody {
    font-size: 14px !important;
}

.icon-container {
    position: relative;
    display: inline-block;
  }
  
  .hover-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the icon */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .icon-container:hover .hover-text {
    visibility: visible;
    opacity: 1;
  }
  

.sidebar-nav .nav-link{
    font-size: 14px !important;
    color: #000;
}
.sidebar-nav .nav-icon{
    color: #000;

}
.sidebar-nav .nav-link.active {
    color:#fff;
    background: #c72739;
}
.sidebar-nav .nav-link:hover {
    color:#fff;
    background: #c72739;
}
.side-white{
    background: #fff;
}


.nainDiv{
    position: absolute;
}

.filters{
display: flex;
justify-content: end;
position: absolute;
right: 15px;
top: 60px;
}


.form-label {
    font-size: 14px;
    margin-bottom: 0;
}

.form-select {
    font-size: 14px;
}

.form-control {
    font-size: 14px;
    border-radius: 0;
}

label {
    font-size: 14px;
}

.btn{
    font-size: 14px;
    margin:5px;
    padding: 0;
}

.small-text{
    font-size: 14px;
}

.small-text-filter{
    font-size: 11px;
}

.action-icons{
    font-size: 14px !important;
}
.color-black{
    color: #000 !important;
}
.color-pink{
    color: #c72739 !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background-color: #c72739;
    color: #fff !important;
    border-color: #c72739;
    border-radius: 0;
}
.nav-tabs .nav-link{
    border-radius: 0;
    color: #000 !important;
    cursor: pointer;
}
.breadcrumb-item a{
    color: #000 !important;
}
.br-0{
    border-radius: 0 !important;
}
.nav-tabs{
    border-color: #c72739 !important;
}
td,th{
    padding: 0px 5px !important;
}

input,
input::placeholder {
    font-size: 14px !important;
}
.btn-primary,.btn-primary:hover, .btn-primary:active,.btn-primary:focus-within,.btn-primary:visited,.btn-primary:focus-visible,.btn-primary:target{
    background: #c72739 !important;
    color: #fff !important;
    border: #c72739 !important;
    border-radius: 0 !important;
    padding: 10px;
}
.mainDiv .row .mb-2 label{
    display: none;
}
.btn-model{
    padding: 5px 15px;
    font-size: 14px;
    
}
.invalid-feedback{
    font-size: 14px;
}
.footer{
    min-height: 0 !important;
}
.featured-ribbon {position: absolute; left: 0; top: 0; z-index: 3; overflow: hidden; width: 70px; height: 70px;}
.featured-ribbon span {position: absolute; top: 20px; left: -21px; font-size: 14px;;	font-weight: bold; color: #FFF;	text-align: center; line-height: 16px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); width: 100px; display: block; background: rgb(199, 39, 57); letter-spacing: 0.8px;}
.ql-editor{
    min-height:200px;
}
.form-check-label{
    font-size: 14px;
}
.form-check-input:checked{
    background-color: #c72739;
    border-color: #c72739;
}
.page-link{
    color: #000;
}
.page-link.active, .active > .page-link{
    background-color: #c72739;
}


.form-check-input {
    margin-top: 5px;
}

.mainDiv .form-control::placeholder{
    font-size: 11px !important;

}

.mainDiv .form-control{
    font-size: 11px !important;
    padding: 7px !important;
    width: 65%;
}

.dashboard-table{
    background-color:#c72739 !important;
    color:#fff !important;
}


.modalImage{
    width:100px !important;
    height:50px !important
}